<template>
  <header>
    <div class="left">
      <template v-if="chat_item">
        <figure>
          <template v-if="chat_item.imageBase64">
            <img
              :src="'data:image/png;base64, ' + chat_item.imageBase64"
              alt="avatar"
            />
          </template>
          <template v-else-if="otherUsers(chat_item)[0].userInfo.avatar">
            <img
              :src="imgURL + otherUsers(chat_item)[0].userInfo.avatar"
              alt="avatar"
            />
          </template>
          <template v-else>
            <span class="user-char" v-if="chat_item.name">
              {{chat_item.name.charAt(0)}}
            </span>
            <span class="user-char" v-else-if="otherUsers(chat_item)[0].userInfo.name">
              {{otherUsers(chat_item)[0].userInfo.name.charAt(0)}}
            </span>
          </template>
          
        </figure>
        <span class="info">
          <span class="title">{{
            chat_item.name || otherUsers(chat_item)[0].userInfo.name
          }}</span>
          <template v-if="chat_item.users.length > 2">
            <div class="participants">
              <span
                class="participant"
                v-for="user in otherUsers(chat_item)"
                :key="user.id"
                >{{ user.userInfo.name }}</span
              >
            </div>
          </template>
        </span>
      </template>
      <template v-else>
        <span>loading</span>
      </template>
    </div>
    <div class="right">
      <span v-if="chatLoaded" @click="startCall"
        ><img src="/assets/images/phone-icon.png" alt=""
      /></span>
      <span class="options" @click="edit = !edit">
        <img src="/assets/images/black_dots.svg" alt="" />
      </span>
    </div>
  </header>
  <div class="wrapper">
    <template v-if="roomCallStatus && !getCallId">
        <div class="join" @click="startCall">
            <span>There is an ongoing call in this room, click here to join it!</span>
            <figure>
                <img src="/assets/images/phone-icon.png" alt="" />
            </figure>
        </div>
    </template>
    <template v-if="loaded">
      <div
        class="video-wrapper"
        :class="{ 'w-video': session && !getDisabled }"
      >
        <div class="innerVideo" id="videochat"></div>
      </div>
      <div class="whole" :class="{ 'w-video': session && !getDisabled }">
        <div class="chat-wrapper" ref="wrapper" @scroll="handleScroll">
            <template v-if="loadingMessages && chatLoaded">
                <div class="loading messages">
                    <span class="loader" />
                </div>
            </template>
          <template v-if="chatLoaded">
            <template
              v-for="(chat, chat_index) in chatsList"
              :key="chat_index"
            >
              <Messages :chat="chat" :replying-to-me="replyingTo && replyingTo._id === chat._id" @joinCall="startCall" @removeChat="handleRemoveChat" @replyToChat="handleReplyToChat"/>
            </template>
            <template
              v-for="(chat, chat_index) in newMessages"
              :key="chat_index"
            >
              <Messages :chat="chat" :replying-to-me="replyingTo && replyingTo._id === chat._id" @joinCall="startCall" @removeChat="handleRemoveNewChats" @replyToChat="handleReplyToNewChat"/>
            </template>
          </template>
          <span class="isTyping" v-if="userIsTyping">
            <img src="/assets/images/typing1.gif" />
          </span>
        </div>
        <template v-if="chatLoaded">
          <Chat :roomId="chatDetails.room" :replying-to="replyingTo" @cancelReply="replyingTo = undefined" />
        </template>
        <template v-else>
          <div class="loading">
            <span class="loader"></span>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </template>
    <transition name="slide">
      <chat-extras
        v-if="edit"
        :info="details"
        :ticket="false"
        :room="chatDetails.room"
        @searchkey="handleSearch"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import {
  GET_CHATS_LIST,
  UPLOAD_CHAT_ATTACHMENT
} from "@/store/storeconstants";
import { getCurrentInstance } from "vue";
import Chat from "@/components/Chat/Chat.vue";
import Messages from "./Messages.vue";
import chatExtras from "@/components/Ui/Chat/ChatExtras.vue";
import nodeInstance from '@/services/NodeServiceinstance';
export default {
  props: {
    chat_item: {
      required: false,
      type: Object,
    },
    mode: {
      required: false,
      type: String,
      defatult: () => "assigned",
    },
    loaded: {
      required: false,
      type: Boolean,
    },
    toRead: {
      required: false,
      type: Number,
    },
  },
  emits: ["reads"],
  data() {
    return {
        internalInstance: getCurrentInstance(),
        currentChatPage: 1,
        chatLoaded: false,
        chatDetails: {},
        newMessages: [],
        currentScroll: 0,
        showVid: false,
        user: localStorage.getItem("U_P")
            ? JSON.parse(localStorage.getItem("U_P"))
            : {},
        org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        session: false,
        imgURL: process.env.VUE_APP_DO_SPACES,
        edit: false,
        userIsTyping: false,
        unreads: 0,
        roomCallStatus: null,
        loadingMessages: false,
        chatsList: [],
        replyingTo: undefined
    };
  },

  components: {
    Chat,
    Messages,
    //optionsModal,
    chatExtras,
  },

  watch: {
    getCallId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        }
        if (val && val === this.chatDetails.room) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },
    chat_item: {
      immediate: true,
      handler(val) {
        this.unreads = val.unreadChatCount;
        this.newMessages = [];
        this.currentScroll = 0;
        this.chatLoaded = false;
        this.currentChatPage = 1;
        this.totalChats = 1;
        this.edit = false;
        this.getChatData();
      },
    },

    currentChatPage() {
      this.getChatData();
    },

    chatDetails: {
      deep: true,
      handler(val) {
        if (this.unreads > 0) this.readMessage();
        if (!this.getCallId) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        } else if (this.getCallId && val.room === this.getCallId) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      getCallStatus: "call/getCallStatus",
      getCallId: "call/getCallId",
      getDisabled: "call/getDisabled",
    }),

    details() {
        return {
            product: {
                product_images: this.otherUsers(this.chat_item)[0].userInfo.avatar ? [{
                   image: this.otherUsers(this.chat_item)[0].userInfo.avatar
                }] : []
            },
            title: this.chat_item.name || this.otherUsers(this.chat_item)[0].userInfo.name
        }
    }
  },

  methods: {
    ...mapActions("chat", {
      updateChatFile: UPLOAD_CHAT_ATTACHMENT,
      fetchChatConversation: GET_CHATS_LIST
    }),

    ...mapActions({
      getRoomCallStatus: "chat/getRoomCallStatus",
    }),

    ...mapMutations({
      setDisabled: "call/setDisabled",
      setCallStatus: "call/setCallStatus",
      setCallId: "call/setCallId",
      setCallInfo: "call/setCallInfo",
    }),

    closeOptions() {
      this.edit = false;
    },

    otherUsers(item) {
      return item.users.filter((el) => el.userId !== this.user.id);
    },

    async getChatData() {
        this.checkStatus()
        this.loadingMessages = true
        
        if (this.chat_item) {
            const response = await nodeInstance.get(`chats/chat-list?room=${this.chat_item._id}&page=${this.currentChatPage}`)

            this.totalChats = Math.ceil(response.data.totalChatCount / 10)
            this.chatLoaded = true;
            this.loadingMessages = false

            
            this.emitter.emit('read-room-chats', {room: response.data.room })

            const wrapper = this.$refs.wrapper;

            if (response && this.currentChatPage === 1) {
                this.chatDetails = response.data;
                this.chatsList = this.chatDetails.chatDetails.reverse()

                setTimeout(() => {
                    wrapper.scrollTop = wrapper.scrollHeight;
                }, 200);
            } else {
                const newChats = response.data.chatDetails.reverse();
                this.chatsList.unshift(...newChats);
                setTimeout(() => {
                    wrapper.scrollTop = wrapper.scrollHeight - this.currentScroll;
                }, 200);
            }
        }
    },

    async checkStatus() {
        this.roomCallStatus = await this.getRoomCallStatus({room: this.chat_item._id,})
    },

    handleRemoveChat(data) {
        const index = this.chatsList.findIndex(el => el._id === data)
        this.chatsList.splice(index, 1)
    },

    handleRemoveNewChats(data) {
        const index = this.newMessages.findIndex(el => el._id === data)
        this.newMessages.splice(index, 1)
    },

    handleReplyToChat(data) {
        const item = this.chatsList.find(el => el._id === data)
        this.replyingTo = item
    },

    handleReplyToNewChat(data) {
        const item = this.newMessages.find(el => el._id === data)
        this.replyingTo = item
    },

    handleScroll(e) {
      if (e.target.scrollTop === 0 && this.currentChatPage < this.totalChats) {
        this.currentScroll = e.target.scrollHeight;
        this.currentChatPage += 1;
      }
    },

    async startCall() {
        let userToSend = this.user;
        userToSend.type = "single";
        const params = {
            roomId: this.chatDetails.room,
            with: userToSend,
        };
        this.setCallStatus(true);
        this.setCallId(this.chatDetails.room);
        
        this.emitter.emit("video-section-prepared", params);

        if (!this.roomCallStatus) this.emitter.emit("request-to-join-call", params);

        this.setCallInfo({
            type: 'chat',
            name: this.chat_item.name || this.otherUsers(this.chat_item)[0].userInfo.name,
            avatar: this.otherUsers(this.chat_item)[0].userInfo.avatar ? this.imgURL + this.otherUsers(this.chat_item)[0].userInfo.avatar : null,
        })
    },

    readMessage() {
      let unreadMsg = [];
      this.chatDetails.chatDetails.forEach((element) => {
        const receivers = element.receivers
          .filter((el) => !el.isRead)
          .map((el) => el.userId);
        if (receivers.length) {
          if (receivers.includes(this.user.id)) unreadMsg.push(element._id);
        }
      });
      const data = {
        room: this.chatDetails.room,
        chatIds: unreadMsg,
      };
      this.emitter.emit("read-message", data);
      this.$emit("reads", unreadMsg.length);
      this.unreads -= unreadMsg.length;
    },

    handleSearch(data) {
      this.chatDetails = {};
      this.newMessages = [];
      this.getChatData(data);
    },
  },

  mounted() {
    this.emitter.on("session-started", (data) => {
        const room = data.room
        if (room === this.chat_item._id) this.checkStatus()
    })

    this.emitter.on("room-read", () => {
      this.unreads = 0
    });

    this.emitter.on("setConfigForCall", () => {
        if(!this.getTicketId) this.emitter.emit('create-chat-call')
    })
    
    this.emitter.on("room-chat-deleted", (data) => {
        if (data.room !== this.chatDetails.room) {
            return
        }

        const chat = this.chatsList.findIndex(el => el._id === data.chat)

        if (chat >= 0) {
            this.chatsList.splice(chat, 1);
            return
        }

        const newChat = this.newMessages.findIndex(el => el._id === data.chat)
        this.newMessages.splice(newChat, 1);
    })

    this.emitter.on("room-chat-edited", (data) => {
        if (data.roomId !== this.chatDetails.room) {
            return
        }

        const chat = this.chatsList.findIndex(el => el._id === data._id)

        if (chat >= 0) {
            this.chatsList[chat].text = data.text;
            this.chatsList[chat].isEdited = true;
            this.chatsList[chat].updatedAt = data.updatedAt;
            return
        }

        const newChat = this.newMessages.findIndex(el => el._id === data._id)
        if (newChat>= 0) {
            this.newMessages[newChat] = data.text;
            this.newMessages[newChat].isEdited = true;
            this.newMessages[newChat].updatedAt = data.updatedAt;
            return
        }
    })

    this.emitter.on("new-message", (data) => {
        if(data.type === 'endCall' || data.type === 'rejectCall') this.checkStatus()

        if (data.room && data.room._id === this.chatDetails.room) {
            let newArr = [
            {
                _id: data._id,
                attachmentUrls: data.attachmentUrls,
                createdAt: data.createdAt,
                media: data.media,
                receivers: data.receivers,
                room: data.room,
                sentBy: data.sentBy,
                status: data.status,
                text: data.text,
                type: data.type,
                mentionedUsers: data.mentionedUsers,
                replyTo: data.replyTo || null
            },
            ];
            this.newMessages.push(...newArr);
            // if (data.type === "rejectCall") this.emitter.emit("destroy-call");

            setTimeout(() => {
            const wrapper = this.$refs.wrapper;
            if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
            }, 200);
        }
    });

    this.emitter.on("chats-deleted", (data) => {
      if (data.room === this.chatDetails.room) {
        this.newMessages = [];
        this.chatDetails.chatDetails = [];
        this.chatDetails.totalChatCount = 0;
      }
    });

    this.emitter.on("user-typing", (data) => {
      if (
        data.room === this.chatDetails.room &&
        data.userDetails.userId !== this.user.id
      ) {
        this.userIsTyping = true;
        const wrapper = this.$refs.wrapper;
        if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;

        clearTimeout(timer);
        const timer = setTimeout(() => {
          this.userIsTyping = false;
        }, 3000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}

.loading {
    &.messages {
        width: 100%;
        height: 12px;

        .loader {
            border: 2px solid #f3f3f3;
            border-top: 2px solid var(--primary-color);
            width: 12px;
            height: 12px;
        }
    }
}

header {
  height: 12%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 1px 6px #cad6e280;
  border-bottom: solid 1px #f2f5f8;
  display: flex;
  justify-content: space-between;
    @media only screen and (max-width: 1200px) {
      padding: 0 20px 0 30px;
    }
  .left {
    display: flex;
    gap: 12px;
    align-items: center;
    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      @media only screen and (max-width: 1200px) {
        width: 30px;
        height: 30px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .user-char {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        background: var(--primary-color);
        color: white;
        border-radius: 50%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 1.2rem;
        font-weight: 500;
        color: black;
      }
      span {
        font-size: 0.8rem;
        color: #cad6e2;
        .name {
          color: black;
        }
      }

      .participants {
        display: flex;
        gap: 5px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      user-select: none;
      cursor: pointer;
    }

    .options {
      position: relative;
      padding: 10px;
    }

    .close-tckt {
      padding: 5px 20px;
      color: #ed0423;
      border-radius: 30px;
      border: solid 1px #ed0423;
      transition: 0.2s;

      &:hover {
        border: solid 1px transparent;
        background: #ed0423;
        color: white;
      }
    }
  }
}
.wrapper {
  padding: 20px 20px 0;
  position: relative;
  height: 88%;
  display: flex;
  .video-wrapper {
    width: 0px;
    &.w-video {
      width: calc(50% - 40px);
      background: black;
      display: flex;
      align-items: center;
    }
    .innerVideo {
      width: 100%;
      height: 100%;
      max-height: 640px;
    }
  }
  .whole {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.w-video {
      width: calc(50% - 40px);
      padding: 0 0 0 20px;
      .chat-wrapper {
        .details-wrapper {
          width: 100%;
        }
      }
    }
    .chat-wrapper {
      height: 95%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px 20px 0;
      transition: 0.2s;
    }
  }

  .chat-bar {
    height: 5%;
  }
}

.test {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}

.isTyping {
  height: 30px;
  img {
    height: 100%;
  }
}

.join {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--primary-color);
    padding: 10px 20px;
    color: white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    figure {
        width: 15px;
        height: 15px;

        img {
            height: 100%;
            filter: brightness(0) invert(1);
        }
    }
}

.loading {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    width: 0px;
    overflow: hidden;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: 25%;
    overflow: hidden;
  }
}
</style>